import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DetailCard from '@/component/DetailCard';
import colors from '@/style/colors';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 8px 5px 8px',
  },
  name: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    flexGrow: 1,
    color: colors.text.dark,
  },
  data: {
    fontSize: 14,
    textAlign: 'right',
    color: colors.text.light,
    paddingLeft: 50,
  },
});

export default function MerchantDetail(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { data } = props;

  const merchantName = data?.name;
  const merchantAddress = data?.address;
  const merchantContact = data?.contact;

  return (
    <DetailCard>
      <div className={classes.row}>
        <span className={classes.name}>{merchantName}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.title}>{t('app.page.orderDetail.merchantDetail.address')}</span>
        <span className={classes.data}>{merchantAddress}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.title}>{t('app.page.orderDetail.merchantDetail.phone')}</span>
        <span className={classes.data}>{merchantContact}</span>
      </div>
    </DetailCard>
  );
}
