
import { Router } from 'react-router-dom';
import Main from './page/Main';
import history from './libs/history';

function App() {
  return (
    <Router history={history}>
      <Main />
    </Router>
  );
}

export default App;
