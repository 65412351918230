import { Divider, makeStyles } from '@material-ui/core';
import { currencyWithCommas } from '@/libs/numberWithCommas';
import { useTranslation } from 'react-i18next';
import DetailCard from '@/component/DetailCard';
import colors from '@/style/colors';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 8px 5px 8px',
  },
  setItemRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 8px 5px 18px',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.text.dark,
  },
  option: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.text.light,
  },
  optionPrice: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.text.light,
    paddingLeft: 10,
  },
  setItemName: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.text.light,
  },
  price: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.text.highlight,
  },
  setItemPrice: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.text.light,
  },
  quantity: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    color: colors.text.dark,
  },
  setItemQuantity: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.text.light,
  },
  divider: {
    height: 1.5,
    background: colors.text.light,
  },
});

export default function CancelledItemDetail(props) {
  const { data } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  if (!data) return null;

  const displayName = (item) => {
    if (item.setName) {
      return `${item.name} [${t('app.page.orderDetail.cancelledItemDetail.bundled')}${item.setName}]`;
    }

    return item.name;
  };

  const displayStaff = (item) => {
    if (item.cancelApprover) {
      return `${item.CancelIdentifier} / ${item.cancelApprover}`;
    }

    return item.CancelIdentifier;
  };

  const displayCancelReason = (cancelReason) => {
    const reason = {
      invalid: t('app.constants.cancelReason.invalid'),
      trial: t('app.constants.cancelReason.trial'),
      rejected: t('app.constants.cancelReason.trial'),
      unavailable: t('app.constants.cancelReason.unavailable'),
      busy: t('app.constants.cancelReason.busy'),
      closed: t('app.constants.cancelReason.closed'),
    };

    return reason[cancelReason] ?? cancelReason;
  };

  const renderOptions = (options, key) => (
    <div key={key}>
      {
        options.map((option) => {
          if (!option) return null;
          return (
            <div key={option.optionId}>
              <span className={classes.option}>{option.name}</span>
              <span className={classes.optionPrice}>
                {option.price
                  ? `(+${currencyWithCommas(option.price)})`
                  : ''}
              </span>
            </div>
          );
        })
      }
    </div>
  );

  return (
    <DetailCard title={t('app.page.orderDetail.cancelledItemDetail.title')}>
      <div className={classes.row}>
        <span className={classes.name} style={{ flex: 5 }}>{t('app.page.orderDetail.cancelledItemDetail.item')}</span>
        <span className={classes.quantity} style={{ flex: 1 }}>{t('app.page.orderDetail.cancelledItemDetail.quantity')}</span>
        <span className={classes.quantity} style={{ flex: 3 }}>{t('app.page.orderDetail.cancelledItemDetail.reason')}</span>
        <span className={classes.quantity} style={{ flex: 4 }}>{t('app.page.orderDetail.cancelledItemDetail.identifier')}</span>
      </div>
      <Divider style={{ background: colors.text.light, height: 1.5 }} />
      {
        data?.map((o) => (
          <div key={o.id}>
            <div className={classes.row}>
              <div className={classes.col} style={{ flex: 5 }}>
                <span className={classes.name}>{displayName(o)}</span>
                {
                  o.options.length !== 0 && (
                    o.options.map((option, key) => renderOptions(option, key))
                  )
                }
                <span className={classes.price}>{currencyWithCommas(o.totals.reduce((a, b) => a + b, 0))}</span>
              </div>
              <span className={classes.quantity} style={{ flex: 1 }}>{o.quantity}</span>
              <span className={classes.quantity} style={{ flex: 3 }}>{displayCancelReason(o.cancelReason)}</span>
              <span className={classes.quantity} style={{ flex: 4 }}>{displayStaff(o)}</span>
            </div>
          </div>
        ))
      }
    </DetailCard>
  );
}
