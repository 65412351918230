export default {
  primary: {
    main: '#E8F0FF',
    dark: '#071D41',
  },
  text: {
    main: '#3590FF',
    light: '#8C8C8C',
    dark: '#4C4C4C',
    highlight: '#EE7B06',
  },
  button: {
    main: '#1C3AAE',
  },
  black: '#000000',
  white: '#FFFFFF',
  grey: '#262626',
  success: '#4caf50',
  error: '#f44336',
  warning: '#ff9800',
};
