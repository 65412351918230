import { CircularProgress, Grid } from '@material-ui/core';
import BatchDetail from './BatchDetail';
import CancelledItemDetail from './CancelledItemDetail';
import Header from '@/component/Header';
import MerchantDetail from './MerchantDetail';
import OrderAppLib from 'dimorder-orderapp-lib';
import OrderOverview from './OrderOverview';
import PaymentDetail from './PaymentDetail';
import PriceDetail from './PriceDetail';
import React from 'react';
import _ from 'lodash';
import dimorderApi from '@/libs/api/dimorderApi';

export default function OrderDetail(props) {
  const orderId = window.location.pathname.replace('/', '');
  const [orderDetail, setOrderDetail] = React.useState(null);
  const [merchantDetail, setMerchantDetail] = React.useState(null);
  const [paymentMethods, setpaymentMethods] = React.useState(null);
  const [apiFail, setApiFail] = React.useState(false);

  React.useEffect(() => {
    const getOrder = async () => {
      try {
        const orderRes = await dimorderApi.getOrderDetail(orderId);
        setOrderDetail(orderRes);
      } catch (error) {
        console.error(error);
        setApiFail(true);
      }
    };
    const getMerchant = async (merchantId) => {
      try {
        const merchantRes = await dimorderApi.getMerchantDetail(merchantId);
        setMerchantDetail(merchantRes);
        setpaymentMethods(merchantRes?.payments);
      } catch (error) {
        console.error(error);
        setApiFail(true);
      }
    };
    if (!orderDetail) {
      getOrder();
    }
    if (orderDetail) {
      getMerchant(orderDetail.merchantId);
    }
    setInterval(() => {
      getOrder();
    }, 30000);
  }, [orderDetail?.merchantId]);

  const sortReceiptItemByCategory = false;
  const receiptGroupBy = 'batch';

  const summarySettings = {
    sortReceiptItemByCategory,
    receiptGroupBy,
  };

  const appOrder = orderDetail ? OrderAppLib.libs.apiOrderToAppOrder(orderDetail) : null;

  const summary = appOrder ? OrderAppLib.libs.formatSummary(appOrder, summarySettings) : null;

  const groupedSummary = summary ? _.groupBy(summary, o => o.batchId) : null;

  const cancelledItems = orderDetail ? orderDetail.items.filter(item => item.cancelled) : null;

  return (
    <>
      <Header />

      {orderDetail && merchantDetail && (
        <Grid
          container
          justifyContent='center'
          direction='row'
        >
          <Grid item xs={12} sm={10} md={4} lg={4} xl={4}>
            <MerchantDetail data={merchantDetail} />
            <OrderOverview data={orderDetail} />
            {
              orderDetail?.batches?.map((batch) => (
                <div key={batch.batchId}>
                  <BatchDetail batch={batch} summary={groupedSummary[batch.batchId]} />
                </div>
              ))
            }
            <PriceDetail data={orderDetail} />
          </Grid>
          {orderDetail?.apiVersion === '2.0.0' && orderDetail?.payments?.length > 0 && (
            <Grid item xs={12} sm={10} md={4} lg={4} xl={3}>
              <PaymentDetail data={orderDetail} paymentMethods={paymentMethods} />
            </Grid>
          )}
          {cancelledItems?.length > 0 && (
            <Grid item xs={12} sm={10} md={4} lg={4} xl={5}>
              <CancelledItemDetail data={cancelledItems} />
            </Grid>
          )}
        </Grid>
      )}
      {
        !(orderDetail && merchantDetail) && !apiFail && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
            <CircularProgress size={70} />
          </div>
        )
      }
    </>
  );
}
