import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import OrderDetail from './OrderDetail';

export default function AppRouter(props) {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={OrderDetail} />
        <Route exact path='/:id' component={OrderDetail} />
      </Switch>
    </BrowserRouter>
  );
}
