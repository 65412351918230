import { displayPaymentMethod } from '@/libs/displayPaymentMethod';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DetailCard from '@/component/DetailCard';
import colors from '@/style/colors';
import moment from 'moment';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 8px 5px 8px',
  },
  serialTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  serial: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.text.highlight,
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    flexGrow: 1,
    color: colors.text.dark,
  },
  data: {
    fontSize: 15,
    textAlign: 'right',
    fontWeight: 'bold',
    color: colors.text.dark,
  },
  mainData: {
    fontSize: 15,
    textAlign: 'right',
    fontWeight: 'bold',
    color: colors.text.main,
  },
});

export default function OrderOverview(props) {
  const classes = useStyles(props);
  const { data } = props;
  const { t } = useTranslation();
  const orderFromWeb = data?.apiVersion === '1.0.0';

  // common
  const serial = data?.orderSerial;
  const displayDeliveryType = (deliveryType) => {
    switch (deliveryType) {
      case 'table': {
        return t('app.constants.deliveryType.table');
      }

      case 'takeaway': {
        return t('app.constants.deliveryType.takeaway');
      }

      case 'storeDelivery': {
        return t('app.constants.deliveryType.storeDelivery');
      }
    }
  };
  const displayStatus = (orderStatus) => {
    switch (orderStatus) {
      case 'cancelled': {
        return t('app.constants.orderStatus.cancelled');
      }

      case 'pending': {
        return t('app.constants.orderStatus.pending');
      }

      case 'paid': {
        return t('app.constants.orderStatus.paid');
      }
    }
  };
  const displayCancelReason = (cancelReason) => {
    const reason = {
      invalid: t('app.constants.cancelReason.invalid'),
      trial: t('app.constants.cancelReason.trial'),
      rejected: t('app.constants.cancelReason.trial'),
      unavailable: t('app.constants.cancelReason.unavailable'),
      busy: t('app.constants.cancelReason.busy'),
      closed: t('app.constants.cancelReason.closed'),
    };

    return reason[cancelReason] ?? cancelReason;
  };
  const createDate = data?.createdAt ? moment(data?.createdAt).format('YYYY-MM-DD') : '';
  const cancelDate = data?.cancelledAt ? moment(data?.cancelledAt).format('YYYY-MM-DD HH:mm') : '';

  // dine-in only
  const customerCount = data?.customers;
  const table = data?.subTable > 0 ? `${data?.table}(${String.fromCharCode(data?.subTable + 64)})` : data?.table;
  const createTime = data?.createdAt ? moment(data?.createdAt).format('HH:mm') : '';

  // takeaway & delivery
  const pickupDate = data?.pickupAt ? moment(data?.pickupAt).format('YYYY-MM-DD') : '';
  const pickupTime = data?.pickupAt ? moment(data?.pickupAt).format('HH:mm') : '';

  return (
    <DetailCard>
      <div className={classes.row}>
        <span className={classes.serialTitle}>{t('app.page.orderDetail.orderOverview.serial')}：</span>
        <span className={classes.serial}>{serial}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.title}>{t('app.page.orderDetail.orderOverview.orderType')}：</span>
        <span className={classes.mainData}>{displayDeliveryType(data?.deliveryType)}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.title}>{t('app.page.orderDetail.orderOverview.orderStatus')}：</span>
        <span
          className={classes.mainData}
          style={{
            color: data?.status === 'pending'
              ? colors.warning
              : data?.status === 'cancelled'
                ? colors.error
                : colors.success,
          }}
        >
          {displayStatus(data?.status)}
        </span>
      </div>
      {
        data?.status === 'cancelled' && (
          <>
            <div className={classes.row}>
              <span className={classes.title}>{t('app.page.orderDetail.orderOverview.cancelledAt')}：</span>
              <span className={classes.data}>{cancelDate}</span>
            </div>

            <div className={classes.row}>
              <span className={classes.title}>{t('app.page.orderDetail.orderOverview.cancelReason')}：</span>
              <span className={classes.data}>{displayCancelReason(data?.cancelReason)}</span>
            </div>

            <div className={classes.row}>
              <span className={classes.title}>{t('app.page.orderDetail.orderOverview.cancelIdentifier')}：</span>
              <span className={classes.data}>{data?.cancelApprover ? `${data?.cancelIdentifier} / ${data?.cancelIdentifier}` : data?.cancelIdentifier}</span>
            </div>
          </>
        )
      }
      <div className={classes.row}>
        <span className={classes.title}>{t('app.page.orderDetail.orderOverview.orderDate')}：</span>
        <span className={classes.data}>{createDate}</span>
      </div>
      {
        data?.deliveryType === 'table' && (
          <>
            <div className={classes.row}>
              <span className={classes.title}>{t('app.page.orderDetail.orderOverview.customer')}：</span>
              <span className={classes.data}>{customerCount}</span>
            </div>
            <div className={classes.row}>
              <span className={classes.title}>{t('app.page.orderDetail.orderOverview.tableNo')}：</span>
              <span className={classes.data}>{table}</span>
            </div>
            <div className={classes.row}>
              <span className={classes.title}>{t('app.page.orderDetail.orderOverview.sitAt')}：</span>
              <span className={classes.data}>{createTime}</span>
            </div>
            {
              orderFromWeb && data?.status === 'paid' && (
                <div className={classes.row}>
                  <span className={classes.title}>{t('app.page.orderDetail.orderOverview.paymentMethod')}：</span>
                  <span className={classes.data}>{displayPaymentMethod(data?.payment)}</span>
                </div>
              )
            }
          </>
        )
      }
      {
        (data?.deliveryType === 'takeaway' || data?.deliveryType === 'storeDelivery') && (
          <>
            <div className={classes.row}>
              <span className={classes.title}>{t('app.page.orderDetail.orderOverview.pickupDate')}：</span>
              <span className={classes.data}>{pickupDate}</span>
            </div>
            <div className={classes.row}>
              <span className={classes.title}>{t('app.page.orderDetail.orderOverview.pickupTime')}：</span>
              <span className={classes.data}>{pickupTime}</span>
            </div>
            {
              data?.from !== 'MERCHANT' && data?.status === 'paid' && (
                <div className={classes.row}>
                  <span className={classes.title}>{t('app.page.orderDetail.orderOverview.paymentMethod')}：</span>
                  <span className={classes.data}>{displayPaymentMethod(data?.payment)}</span>
                </div>
              )
            }
          </>
        )
      }
    </DetailCard>
  );
}
