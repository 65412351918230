import { useTranslation } from 'react-i18next';

export function displayPaymentMethod (paymentKey, paymentMethods) {
  const payment = paymentMethods?.find(o => o.key === paymentKey);
  const { t } = useTranslation();

  switch (paymentKey) {
    case 'cash': {
      return t('app.constants.paymentMethods.cash');
    }

    case 'alipay': {
      return t('app.constants.paymentMethods.alipay');
    }

    case 'visa': {
      return t('app.constants.paymentMethods.visa');
    }

    case 'master': {
      return t('app.constants.paymentMethods.master');
    }

    case 'ae': {
      return t('app.constants.paymentMethods.ae');
    }

    case 'octopus': {
      return t('app.constants.paymentMethods.octopus');
    }

    case 'payme': {
      return t('app.constants.paymentMethods.payme');
    }

    case 'tng': {
      return t('app.constants.paymentMethods.tng');
    }

    case 'unionPay': {
      return t('app.constants.paymentMethods.unionPay');
    }

    case 'wechat': {
      return t('app.constants.paymentMethods.wechat');
    }

    case 'wechatpay': {
      return t('app.constants.paymentMethods.wechat');
    }

    case 'bypass': {
      return t('app.constants.paymentMethods.creditCard');
    }

    case 'creditCardCheckout': {
      return t('app.constants.paymentMethods.creditCard');
    }

    case 'fps': {
      return t('app.constants.paymentMethods.fps');
    }

    default: {
      if (payment) {
        return payment?.name;
      } else {
        return paymentKey;
      }
    }
  }
}
