import { Card, CardContent, Divider, makeStyles } from '@material-ui/core';
import colors from '@/style/colors';

const useStyles = makeStyles({
  card: {
    margin: 10,
    padding: 0,
    borderRadius: 10,
    boxShadow: 'rgba(0, 0, 0, 0.05) 5px 5px 5px',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: 25,
  },
  titleContainer: {
    padding: '15px 0px 0px',
  },
});

export default function DetailCard(props) {
  const classes = useStyles(props);
  const { children, style = {}, title = '' } = props;

  return (
    <Card className={classes.card} style={style}>
      {title && (
        <div className={classes.titleContainer}>
          <span className={classes.title}>{title}</span>
          <Divider style={{ background: colors.text.light, height: 1.5, margin: '15px 0px 0px' }} />
        </div>
      )}
      <CardContent style={{ padding: '20px 15px 20px 15px' }}>
        {children}
      </CardContent>
    </Card>
  );
}
