import axios from 'axios';

import config from '@/config';

export class dimorderApi {
  constructor(url) {
    this.axios = axios.create({
      baseURL: url || config.baseUrl,
    });
  }

  async getOrderDetail(id) {
    const response = await this.axios.get(`/g/order/${id}`);

    return response.data;
  }

  async getMerchantDetail(id) {
    const response = await this.axios.get(`/g/merchant/${id}`);

    return response.data;
  }
}

export default new dimorderApi();
