import { Divider, makeStyles } from '@material-ui/core';
import { currencyWithCommas } from '@/libs/numberWithCommas';
import { useTranslation } from 'react-i18next';
import DetailCard from '@/component/DetailCard';
import clsx from 'clsx';
import colors from '@/style/colors';
import moment from 'moment'; ;

const useStyles = makeStyles({
  summaryRow: {},
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 8px 5px 8px',
  },
  optionRow: {
    paddingTop: 0,
  },
  itemRow: {
    paddingBottom: 0,
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 8px 5px 8px',
    justifyContent: 'space-between',
    backgroundColor: colors.primary.dark,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.text.dark,
    flex: 4,
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    flexGrow: 1,
    color: colors.text.dark,
  },
  data: {
    fontSize: 15,
    fontWeight: 'bold',
    color: colors.white,
  },
  option: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.text.light,
  },
  optionPrice: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.text.light,
    paddingLeft: 10,
  },
  setItemName: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.text.light,
    flex: 4,
  },
  price: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.text.highlight,
    textAlign: 'right',
    flex: 1,
  },
  setItemPrice: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.text.light,
    textAlign: 'right',
    flex: 1,
  },
  quantity: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.text.dark,
    flex: 1,
  },
  setItemQuantity: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.text.light,
    flex: 1,
  },
  divider: {
    height: 1.5,
    background: colors.text.light,
  },
});

export default function BatchDetail(props) {
  const { batch, summary } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  if (!batch) return null;

  const renderOptions = (options) => (
    <>
      {
        options.map((option) => {
          if (!option) return null;
          return (
            <div key={option.optionId}>
              <span className={classes.option}>{option.name}</span>
              <span className={classes.optionPrice}>
                {option.price
                  ? `(+${currencyWithCommas(option.price)})  x ${option.quantity}`
                  : ''}
              </span>
            </div>
          );
        })
      }
    </>
  );

  const renderSetItems = (items) => (
    <>
      <Divider style={{ background: colors.text.light }} />
      {
        items.map((item) => (
          <div key={item.id}>
            <div className={classes.row}>
              <span className={classes.setItemName}>{`${t('app.page.orderDetail.itemDetail.bundled')} ${item.name}`}</span>
              <span className={classes.setItemQuantity}>{item.quantity}</span>
              <span className={classes.setItemPrice}>{currencyWithCommas(item.total)}</span>
            </div>
            <div className={classes.row}>
              <div className={classes.col}>
                {
              item.options.length !== 0 && (
                renderOptions(item.options)
              )
            }
              </div>
            </div>
          </div>
        ))
      }
      <Divider style={{ background: colors.text.light }} />
    </>
  );

  return (
    <DetailCard>
      <div className={classes.titleRow}>
        <span className={classes.data}>{moment(batch.createdAt).format('HH:mm')}</span>
        <span className={classes.data}>{batch.from}</span>
        <span className={classes.data}>{batch.identifier}</span>
      </div>
      {
        summary?.map((item) => (
          <div className={classes.summaryRow} key={item.id}>
            <div className={clsx(classes.row, classes.itemRow)}>
              <span className={classes.name}>{item.name}</span>
              <span className={classes.quantity}>{item.quantity}</span>
              <span className={classes.price}>{currencyWithCommas(item.price)}</span>
            </div>
            <div className={clsx(classes.row, classes.optionRow)}>
              <div className={classes.col}>
                {
                item.options.length !== 0 && (
                  renderOptions(item.options)
                )
              }
              </div>
            </div>
            {
              item.items.length !== 0 && (
                renderSetItems(item.items.filter(item => !item.cancelled))
              )
            }
          </div>
        ))
      }
    </DetailCard>
  );
}
