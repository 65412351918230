import { AppBar, IconButton, Toolbar, Tooltip, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Earth from '@/assets/Earth';
import React from 'react';
import colors from '@/style/colors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    background: colors.primary.dark,
    boxShadow: 'none',
  },
  title: {
    flexGrow: 1,
    padding: 10,
    color: colors.white,
    fontSize: 20,
    fontWeight: 'bold',
  },
  button: {
    padding: 10,
    color: colors.white,
  },
});

export default function Header(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = React.useState('zh-HK');

  const changeLanguage = () => {
    if (lang === 'zh-HK') {
      setLang('en-US');
      i18n.changeLanguage('en-US');
    }

    if (lang === 'en-US') {
      setLang('zh-HK');
      i18n.changeLanguage('zh-HK');
    }
  };

  return (
    <AppBar position='static' className={classes.container}>
      <Toolbar style={{ display: 'flex' }}>
        <span className={classes.title}>{t('app.component.header.title')}</span>
        <Tooltip title={i18n.language === 'zh-HK' ? 'ENG' : '中文'}>
          <IconButton
            className={classes.button}
            aria-label='locale'
            component='span'
            onClick={changeLanguage}
          >
            <Earth />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}
