import { Divider, makeStyles } from '@material-ui/core';
import { currencyWithCommas } from '@/libs/numberWithCommas';
import { useTranslation } from 'react-i18next';
import DetailCard from '@/component/DetailCard';
import colors from '@/style/colors';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 8px 5px 8px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    flexGrow: 1,
    color: colors.text.dark,
  },
  data: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    color: colors.text.highlight,
  },
  totalTitle: {
    fontSize: 18,
    fontWeight: 900,
    flexGrow: 1,
  },
  totalData: {
    fontSize: 18,
    fontWeight: 900,
    textAlign: 'right',
    color: colors.text.highlight,
  },
});

export default function PriceDetail(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { data } = props;
  const subtotal = data?.apiVersion === '2.0.0' ? data?.subtotal : data?.originalTotal;
  const roundedTotal = data?.deliveryType === 'table' || (data?.from === 'MERCHANT' && data?.deliveryType === 'takeaway')
    ? data?.roundedTotal
    : data?.merchantPortalRoundedTotal;
  // handle 1.0 C Web promocode
  const oldDiscount = data?.apiVersion === '1.0.0' ? data?.discount : null;

  const itemModifiers = [];
  const orderModifiers = data?.modifiers?.filter((o) => {
    return o.calculatedAmount !== 0 && o.offeredBy !== 'DimOrder';
  });

  data?.batches?.forEach((batch) => {
    batch.items?.forEach((item) => {
      if (!item.cancelled && item.modifiers.length > 0) {
        item.modifiers.forEach((o) => {
          o.forEach((modifier) => {
            if (modifier.offeredBy !== 'DimOrder' && modifier.type === 'DISCOUNT') {
              itemModifiers.push({
                item: item.name,
                modifier: modifier,
              });
            }
          });
        });
      }
    });
  });

  const renderModifierName = (modifier, type, item) => {
    if (modifier.custom) {
      if (type === 'item') {
        return `${modifier.customName} - ${item} ${modifier.percent ? `(${modifier.percent}%)` : ''}`;
      }
      if (type === 'order') {
        return `${modifier.customName} ${modifier.percent ? `(${modifier.percent}%)` : ''}`;
      }
    } else {
      if (type === 'item') {
        switch (modifier.type) {
          case 'DISCOUNT': {
            return `${t('app.page.orderDetail.priceDetail.itemDiscount')} - ${item} ${modifier.percent ? `(${modifier.percent}%)` : ''}`;
          }
        }
      }
      if (type === 'order') {
        switch (modifier.type) {
          case 'DISCOUNT': {
            return `${t('app.page.orderDetail.priceDetail.orderDiscount')} ${modifier.percent ? `(${modifier.percent}%)` : ''}`;
          }
          case 'SURCHARGE': {
            return `${t('app.page.orderDetail.priceDetail.surcharge')} ${modifier.percent ? `(${modifier.percent}%)` : ''}`;
          }
          case 'PROMOCODE': {
            return `${t('app.page.orderDetail.priceDetail.promocode')} - ${modifier.code} ${modifier.percent ? `(${modifier.percent}%)` : ''}`;
          }
        }
      }
    }
  };

  const renderItemModifers = () => (
    <>
      {
        itemModifiers?.map((o) => (
          <div key={o.modifier.id} className={classes.row}>
            <span className={classes.title}>{renderModifierName(o.modifier, 'item', o.item)}</span>
            <span className={classes.data}>{currencyWithCommas(o.modifier.calculatedAmount)}</span>
          </div>
        ))
      }
    </>
  );

  const renderOrderModifers = () => (
    <>
      {
        orderModifiers?.map((modifier) => {
          if (modifier.type === 'SHIPPING_DISCOUNT') return null;
          return (
            <div key={modifier.id} className={classes.row}>
              <span className={classes.title}>{renderModifierName(modifier, 'order')}</span>
              <span className={classes.data}>{currencyWithCommas(modifier.calculatedAmount)}</span>
            </div>
          );
        })
      }
    </>
  );

  const renderCWebDiscount = () => (
    <>
      {
      data?.apiVersion === '1.0.0' && Math.abs(data?.calculatedDiscountTotal) - Math.abs(data?.discountShippingFee) !== 0 &&
      (
        <div className={classes.row}>
          <span className={classes.title}>
            {`${oldDiscount?.code ? `${t('app.page.orderDetail.priceDetail.promocode')} - ${oldDiscount?.code}` : '全單折扣'} 
            ${oldDiscount?.percent ? `(${oldDiscount?.percent}%)` : ''}`}
          </span>
          <span className={classes.data}>{currencyWithCommas(-1 * (Math.abs(data?.calculatedDiscountTotal) - Math.abs(data?.discountShippingFee)))}</span>
        </div>
      )
       }
    </>
  );

  const renderRoundedAmount = () => (
    <>
      {data?.roundedAmount !== 0 && (
        <div className={classes.row}>
          <span className={classes.title}>
            {t('app.page.orderDetail.priceDetail.roundedAmount')}
          </span>
          <span className={classes.data}>{currencyWithCommas(data?.roundedAmount)}</span>
        </div>
      )}
    </>
  );

  return (
    <DetailCard>
      <div className={classes.row}>
        <span className={classes.title}>{t('app.page.orderDetail.priceDetail.subtotal')}</span>
        <span className={classes.data}>{currencyWithCommas(subtotal)}</span>
      </div>
      {renderItemModifers()}
      {renderOrderModifers()}
      {renderCWebDiscount()}
      {renderRoundedAmount()}
      <Divider style={{ background: colors.text.light, height: 1.5 }} />
      <div className={classes.row}>
        <span className={classes.totalTitle}>{t('app.page.orderDetail.priceDetail.total')}</span>
        <span className={classes.totalData}>{currencyWithCommas(roundedTotal)}</span>
      </div>
    </DetailCard>
  );
}
