export function currencyWithCommas(
  num,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
) {
  if (num === undefined || num === null) {
    num = 0;
  }

  return Number(num).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });
}

export function numberWithCommas(num) {
  return Number(num).toLocaleString();
}

export default {
  numberWithCommas,
  currencyWithCommas,
};
