import { Divider, makeStyles } from '@material-ui/core';
import { currencyWithCommas } from '@/libs/numberWithCommas';
import { displayPaymentMethod } from '@/libs/displayPaymentMethod';
import { useTranslation } from 'react-i18next';
import DetailCard from '@/component/DetailCard';
import colors from '@/style/colors';
import moment from 'moment';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 8px 5px 8px',
  },
  totalRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 8px 15px 8px',
  },
  totalTitle: {
    fontSize: 18,
    fontWeight: 900,
    flexGrow: 1,
    color: colors.text.highlight,
  },
  totalData: {
    fontSize: 18,
    fontWeight: 800,
    textAlign: 'right',
    color: colors.text.highlight,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    flexGrow: 1,
    color: colors.text.dark,
  },
  data: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    color: colors.text.dark,
  },
  highlightedData: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    color: colors.text.highlight,
  },
  priceData: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    color: colors.text.highlight,
  },
});

export default function PaymentDetail(props) {
  const { data, paymentMethods } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  const payments = data?.payments?.filter((payment) => {
    return payment.status === 'paid';
  });
  const isTable = data?.deliveryType === 'table';
  const paidByCustomerText = t('app.page.orderDetail.paymentDetail.paidByCustomer');

  const renderPaymentsDetail = () => (
    <>
      {
        payments?.map((payment) => {
          const paidByCustomer = !!payment.customerId;
          return (
            <div key={payment.id}>
              <div className={classes.row}>
                <span className={classes.title}>{t('app.page.orderDetail.paymentDetail.paymentTime')}：</span>
                <span className={classes.data}>
                  {payment.createdAt ? moment(payment.createdAt).format('YYYY-MM-DD HH:mm') : ''}
                </span>
              </div>

              <div className={classes.row}>
                <span className={classes.title}>{t('app.page.orderDetail.paymentDetail.paymentMethod')}：</span>
                <span className={classes.data}>
                  {paidByCustomer && isTable && (<span className={classes.highlightedData}>({paidByCustomerText}) </span>)}
                  {displayPaymentMethod(payment.paymentMethod, paymentMethods)}
                </span>
              </div>

              <div className={classes.row}>
                <span className={classes.title}>{t('app.page.orderDetail.paymentDetail.paidAmount')}：</span>
                <span className={classes.priceData}>
                  {currencyWithCommas(payment.paidAmount)}
                </span>
              </div>

              {
              payment.change > 0 && (
                <div className={classes.row}>
                  <span className={classes.title}>{t('app.page.orderDetail.paymentDetail.change')}：</span>
                  <span className={classes.priceData}>
                    {currencyWithCommas(payment.change)}
                  </span>
                </div>
              )
            }

              <div className={classes.row}>
                <span className={classes.title}>{t('app.page.orderDetail.paymentDetail.tips')}：</span>
                <span className={classes.priceData}>
                  {currencyWithCommas(payment.paymentTips)}
                </span>
              </div>

              <Divider style={{ background: colors.text.light, marginBottom: 15 }} />
            </div>
          );
        })
      }
    </>
  );

  return (
    <DetailCard title={t('app.page.orderDetail.paymentDetail.title')}>
      <div className={classes.totalRow}>
        <span className={classes.totalTitle}>{t('app.page.orderDetail.paymentDetail.amount')}：</span>
        <span className={classes.totalData}>{currencyWithCommas(data?.roundedTotal)}</span>
      </div>
      <Divider style={{ background: colors.text.light, marginBottom: 15 }} />
      {renderPaymentsDetail()}
    </DetailCard>
  );
}
